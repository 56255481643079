import React from "react"
// import { Link } from "gatsby"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import CategoriesHeaderImage from "../components/categories-header"
import PlacesBackgroundImage from "../components/places-background-image"
import PeopleBackgroundImage from "../components/people-background-image"
// import ProcessesBackgroundImage from "../components/processes-background-image"
// import TechnologyBackgroundImage from "../components/technology-background-image"
import BandsCircle from "../components/bands-circle"
import BandsDiamond from "../components/bands-diamond"
import Row from "../components/bootstrap/row"
import AwardCategory from "../components/award-category"
import AppModal from "../components/app-modal"
import PrimaryButton from "../components/primary-button"

const CategoriesPage = () => (
  <Background>
    <Layout>
      <Seo title="Award Categories" />
      <Container>
        <PageTitle text="Award Categories" />
        <div className="text-center mb-5">
          <PrimaryButton to="/shortlist/" text="View the Shortlist" />
        </div>
        <div style={{
          position: `relative`
        }}>
          <div style={{
            backgroundColor: `#000000`,
            width: `100%`,
            height: `100%`,
            opacity: `60%`,
            position: `absolute`,
            top: `0`,
            left: `0`,
            zIndex: `1`
          }}></div>
          <CategoriesHeaderImage />
        </div>
        <div style={{
          fontFamily: `Din Pro`,
          fontWeight: `400`,
          fontSize: `1rem`,
          color: `#fff`,
          background: `linear-gradient(90deg, rgba(210,52,100,.9) 0%, rgba(251,146,1,.9) 100%)`
        }}>
          <Container>
            <div className="row" style={{ padding: `2rem`}}>
              <div className="col-sm">
                <h4 style={{
                  fontWeight: `bold`,
                  padding: `1.5rem`
                }}>IN ADDITION TO THE CATEGORY-SPECIFIC CRITERIA, THE JUDGES WILL BE LOOKING FOR THE FOLLOWING FROM ENTRIES:</h4>
                <ul>
                  <li style={{marginBottom: `1rem`}}>Innovation, Imagination and Ambition</li>
                  <li style={{marginBottom: `1rem`}}>Evidence of a clear strategy for the project / policy, clearly communicated to staff and other stakeholders</li>
                  <li style={{marginBottom: `1rem`}}>Evidence of good management</li>
                  <li style={{marginBottom: `1rem`}}>Value for money</li>
                  <li style={{marginBottom: `1rem`}}>Supporting evidence that the project / policy has made a difference to services.</li>
                  <li>Evidence it has achieved or is well on the way to achieving what it set out to do</li>
                </ul>
              </div>
              <div className="col-sm">
                <div style={{
                  border: `1px solid #fff`,
                  padding: `2rem`,
                  paddingBottom: `1rem`
                }}>
                  <ul>
                    <li style={{marginBottom: `1rem`}}>If you are making different entries under several different categories, please indicate this and complete an entry for each. </li>
                    <li style={{marginBottom: `1rem`}}>Each application needs to specifically address the criteria for each individual category.</li>
                    <li style={{marginBottom: `1rem`}}>The same project can be entered in different categories but a separate submission must be sent for each one, geared towards the category it is entered into.</li>
                    <li style={{marginBottom: `1rem`}}>Public sector organisations  can still enter multiple submissions for the same category with different projects, should they so choose.</li>
                    <li style={{marginBottom: `1rem`}}>Entries are scored using the published criteria for each category in addition to the general criteria.</li>
                  </ul>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Container>
      
      <Container>
        <br /><br /><br /><br />
        <Container>
          <div style={{ position: `relative`}}>
            <BandsCircle style={{ position: `absolute`, bottom: `-6rem`, right: `-14rem`, zIndex: `1`}} size="20rem" />    
            <BandsDiamond type="fine" style={{ position: `absolute`, left: `-2.5rem`, top: `-2.5rem`}} />
            <div style={{
              background: `linear-gradient(90deg, rgba(251,146,1,1) 0%, rgba(210,52,100,1) 100%)`,
              width: `10rem`,
              height: `10rem`,
              borderRadius: `50%`,
              position: `absolute`,
              bottom: `7rem`,
              right: `-15rem`,
              zIndex: `-1`
            }} />
            <div className="row">
              <div className="col-md" style={{
                background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                padding: `3rem`,
                position: `relative`
              }}>
                <div style={{ margin: `0 1.1rem`}}>
                  <h4 style={{
                    display: `inline`,
                    backgroundColor: `#d23464`,
                    padding: `.5rem 0`,
                    lineHeight: `3.4rem`,
                    boxShadow: `1.2rem 0 0 #d23464, -1.2rem 0 0 #d23464`,
                    textTransform: `uppercase`,
                    color: `#fff`,
                    fontFamily: `Avenir`,
                    fontWeight: `900`
                  }}>The Categories<br /><small>Enter Your Nominations</small></h4>
                </div>
                <p style={{
                  color: `#fff`,
                  fontFamily: `Din Pro`,
                  marginTop: `1.5rem`
                }}>Below are the main Smarter Working Live award categories, comprising of 10 awards which your organisation or team can submit entries to. Before submitting your entry, ensure you have read the criteria listed under each award.<br /><br />View the category-specific criteria for each award by clicking the 'Read more' button.<br /><br /><strong style={{fontSize: `18px`}}>Extended deadline for entries is Friday 9th December 2022</strong></p>
              </div>
              <PeopleBackgroundImage className="col-md" />
            </div>
          </div>
        </Container>
        <br /><br /><br />
        <Container>
          <Row>
            <div className="col-md p-lg-4">
              <AwardCategory title="Improving Spaces - Creating A Great Place to Work" gridColor="#d23464" icon="computer-desk">
                <AppModal openButton="Read More">
                  <AwardCategory title="Improving Spaces - Creating A Great Place to Work" gridColor="#d23464" icon="computer-desk">
                    <p>To build a great place to work, employers need to continually and consciously provide improved spaces to deliver great workplace experiences. With this comes valuable opportunities to foster more inclusive and more diverse workplaces where people feel valued.</p>
                    <p>This award recognises organisations who have consistently aimed to provide improved workspaces for the people that use it, which has resulted in long-term benefits.</p>

                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Workspaces that have inspired greater collaboration, creativity, or communication among people (internal or external)</li>
                      <li>Projects that encourage benefits to wellbeing or contribute to flexible hybrid working models</li>
                      <li>Workspaces that can demonstrate inclusive design that improves accessibility for all</li>
                      <li>Workspaces that have been improved by listening to feedback from employees</li>
                      <li>Projects where data-driven decisions have brought genuine change to people in their workplaces</li>
                      <li>Projects which showcase how a user-centric approach has transformed services and accelerated growth and support current and future business needs</li>
                    </ul>

                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
            <div className="col-md p-lg-4">
              <AwardCategory title="Future Focused and Sustainable Property" gridColor="#30c4bf" icon="office">
                <AppModal openButton="Read More">
                  <AwardCategory title="Future Focused and Sustainable Property" gridColor="#30c4bf" icon="office">
                    <p>The concept of physical workplaces is constantly evolving; it requires careful planning to design and create a workspace that is fit-for-purpose, modern and sustainable.</p>
                    <p>This award recognises organisations who have shown pioneering ambition in their design and build of workplaces that meet these three foundations. We are seeking projects where functionality, innovation and sustainability come together to form spaces where people can creatively and effectively work together, inspired by their surroundings.</p>

                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>

                    <ul>
                      <li>Workspaces that have produced greater value and/or service delivery for staff and customers</li>
                      <li>Workspaces that are innovative in their design and build, which demonstrate functionality or sustainability (whether it be through green energy and progressing to net-zero or community shared spaces)</li>
                      <li>Projects where multiple partners and stakeholders have collaborated to build a modern and sustainable space</li>
                      <li>Projects which have adopted modern technology, upgraded conditions or driven sustainability efforts to support greater workplace services.</li>
                      <li>Projects that promote asset efficiency and optimise utilisation</li>
                      <li>Where appropriate, indicate what the space was previously used for and how it has been better maximised</li>
                    </ul>

                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
          </Row>
          <Row>
            <div className="col-md p-lg-4">
              <AwardCategory title="Digital Transformation - Enabling New Ways of Working" gridColor="#30c4bf" icon="arrows-cog">
                <AppModal openButton="Read More">
                  <AwardCategory title="Digital Transformation - Enabling New Ways of Working" gridColor="#30c4bf" icon="arrows-cog">
                    <p>Digital transformation is no longer a future concept. It is here; it has been changing the face of public services for well over a decade and will continue to do so through smarter working. As digital transformation opportunities become more accessible across the public sector, flexibility, collaboration, community, and culture are flourishing because of it.</p>
                    <p>This award is for organisations who have pioneered beyond 'digital for digital's sake' in their digital transformation journey, in whatever stage they are at, to truly empower their people and the services they deliver.</p>
                    <p>Examples of entries could include (but are not limited to *):</p>
                    <ul>
                      <li>Projects which have made use of digital to deliver great workplace experiences for users</li>
                      <li>Projects which have introduced digital tools to enable customer services to be delivered from anywhere</li>
                      <li>Projects which have used digital tools to improve the user/customer experience or operations (internal and external)</li>
                      <li>Projects which have improved systems, processes, or workload for people</li>
                      <li>Projects which have used digital to reduce carbon footprint or promote sustainable initiatives</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
            <div className="col-md p-lg-4">
              <AwardCategory title="Automation, Artificial Intelligence and Machine Learning" gridColor="#d23464" icon="brain">
                <AppModal openButton="Read More">
                  <AwardCategory title="Automation, Artificial Intelligence and Machine Learning" gridColor="#d23464" icon="brain">
                    <p>Automation, Artificial Intelligence (AI) and machine learning is the fastest growing technology in the world. And for good reason - when used effectively, the benefits are far-reaching - proving to boost effectiveness, free up staff time, reduce waste and produce significant cost-savings; it can inform decision-making and is a vital tool to drive maximum value in operations and service delivery.</p>
                    <p>This award recognises organisations who have implemented technology innovations to overcome challenges in managing large amounts of data or repetitive processes.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Projects which have prioritised innovation in technology to produce efficiency savings in productivity, costs or sustainability, with results being measured over time</li>
                      <li>Projects where automation, AI and machine learning has resolved long-standing issues or inefficiencies in an organisation or team</li>
                      <li>Projects where automation, AI and machine learning has opened opportunities for future technological advancements and ways of working</li>
                      <li>Projects that are enabling more rapid implementation of digitalisation, converting paper or digital images into data to power transformative technology</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
          </Row>
          <Row>
            <div className="col-md p-lg-4">
              <AwardCategory title="Harnessing the Value of Data" gridColor="#d23464" icon="binary">
                <AppModal openButton="Read More">
                  <AwardCategory title="Harnessing the Value of Data" gridColor="#d23464" icon="binary">
                    <p>Data is at the heart of public sector processes and decision making. While digitalisation is undoubtedly opening up new possibilities and bringing widespread benefits right across the public sector, organisations must be mindful of the challenges to be overcome. With our increased range of work locations and technology platforms, encouraging staff to create and share data responsibly is vital. Issues of cyber security are increasingly important.</p>
                    <p>This award is for organisations which have demonstrated excellence in building data into decision making, understanding threat factors and ensuring safeguards are in place to maximise the responsible use of data.</p>
                    <p>Examples of entries could include (but are not limited to*):</p>
                    <ul>
                      <li>Projects that have used data to set work and workplace strategies</li>
                      <li>Projects which have ensured the ability for people to work from anywhere while access to systems is carefully monitored and managed</li>
                      <li>Projects which promote and encourage data and cyber security awareness and understanding, giving teams the knowledge and information to remain constantly aware in different work settings</li>
                      <li>Projects which have accelerated the delivery of safer processes, systems and software to unlock new ways of working</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
            <div className="col-md p-lg-4">
              <AwardCategory title="Small-scale, Big Impact" gridColor="#30c4bf" icon="inward-arrows-square">
                <AppModal openButton="Read More">
                  <AwardCategory title="Small-scale, Big Impact" gridColor="#30c4bf" icon="inward-arrows-square">
                    <p>Budgets should not limit the impact a project can achieve. This award is for small scale projects that have produced remarkable outcomes, such as built community links, generated sustainable initiatives or improved service delivery.</p>
                    <p>Organisations that will stand out are those who can demonstrate how they managed the size of the project to their advantage, using creative planning and innovation to resolve problems.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Projects driven by strategic planning and decision-making to get off the ground</li>
                      <li>Projects influenced by challenges to budget restrictions (or other restrictions).</li>
                      <li>Projects focussed on bringing small but impactful changes to services</li>
                      <li>Projects led by individuals or teams to make meaningful change to staff or the user/customer experience</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
          </Row>
          <Row>
            <div className="col-md p-lg-4">
              <AwardCategory title="Building Collaborative Communities" gridColor="#30c4bf" icon="circle-people">
                <AppModal openButton="Read More">
                  <AwardCategory title="Building Collaborative Communities" gridColor="#30c4bf" icon="circle-people">
                    <p>Delivering successful public sector services is reliant on communication and collaboration. They are the foundation that brings people, technology, and quality services together. And with hybrid working models continuing to mould workforce cultures, these channels have perhaps never been as fundamental as now.</p>
                    <p>This award is for organisations who have shown strong commitment to expanding communication and collaboration opportunities – whether it be for public sector staff or for users/customers.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Projects which have sought to empower staff or users/customers with creative solutions to previous communication or collaboration barriers</li>
                      <li>Projects which have championed digital inclusion with careful and considered planning</li>
                      <li>Projects which have helped to drive collaboration and engagement in changes to ways of working</li>
                      <li>Projects which have tested or trialled different initiatives to find the solution that best suits the needs of staff and/or users/customers</li>
                      <li>Projects that have engaged with local communities/groups to drive inclusion and local engagement</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
            <div className="col-md p-lg-4">
              <AwardCategory title="Putting People First" gridColor="#d23464" icon="inspire">
                <AppModal openButton="Read More">
                  <AwardCategory title="Putting People First" gridColor="#d23464" icon="inspire">
                    <p>Providing proactive wellbeing support is a fundamental duty of care for all organisations. The support in place for people is inherently linked to how people feel in their work and their lives; it carries personal benefits but in turn enhances the effectiveness of operations and services. Its importance cannot be overlooked or understated.</p>
                    <p>This award is for projects which have sought to champion people - their mental health and wellbeing, their support networks and any other opportunities to improve the quality of life for their people.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Projects which have prioritised mental health and wellbeing in the workspace or in the services they deliver, based on employee or user/customer feedback.</li>
                      <li>Projects which have helped to shape greater inclusivity and diversity in workforces</li>
                      <li>Projects which have contributed to attracting and retaining talent in workforces</li>
                      <li>Projects which have navigated challenges to improve flexibility in how people work or receive services</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
          </Row>
          <Row>
            <div className="col-md p-lg-4">
              <AwardCategory title="Championing Continuous Improvement" gridColor="#d23464" icon="three-up-arrows">
                <AppModal openButton="Read More">
                  <AwardCategory title="Championing Continuous Improvement" gridColor="#d23464" icon="three-up-arrows">
                    <p>Continuous Improvement is an important element of ensuring programmes and projects are embedded within organisations past BAU. This is particularly important where ways of working are concerned. Ensuring old behaviours and habits don’t creep back in where progress has been made is critical.</p>
                    <p>This award is for organisations who have applied successful continuous improvement approaches to their ways of working approaches so that adoption continues to be successful.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Projects which have empowered staff to take ownership of local ways of working with test and learn approaches</li>
                      <li>Projects that have embedded smarter working into wider organisation strategies to ensure it is part of day to day activities</li>
                      <li>Projects which have actioned employee feedback to level up development/training and opportunities in meaningful ways</li>
                      <li>Projects that have identified and supported people with changes in habits or culture required to sustain new ways of working</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
            <div className="col-md p-lg-4">
              <AwardCategory title="Outstanding Smarter Working Leadership" gridColor="#30c4bf" icon="leader">
                <AppModal openButton="Read More">
                  <AwardCategory title="Outstanding Smarter Working Leadership" gridColor="#30c4bf" icon="leader">
                    <p>Embracing smarter working requires strong leadership to drive culture change throughout an organisation. Outstanding leaders work together in equal partnerships with their people to find solutions, demonstrating trust and outcomes based leadership.</p>
                    <p>This award is for organisations, teams or individuals who have role modelled smarter working leadership, empowering staff or users/customers to transform services.</p>
                    <p><strong>Examples of entries could include (but are not limited to *):</strong></p>
                    <ul>
                      <li>Organisational leadership where leaders (at any level of the organisation) are taking an active role in driving smarter working strategy</li>
                      <li>Projects that have created an organisation culture where the right behaviours are encouraged and recognised</li>
                      <li>Projects that have an active champions network who are empowered to act as leaders in local teams</li>
                      <li>Projects that equip leaders with the tools and mindset to develop outcomes based leadership</li>
                    </ul>
                    <strong style={{ color: `rgb(210, 52, 100)` }}><em>*This list is not exhaustive. If you or your organisation has undergone a similar project that may fit into this category but is not on the list, we still encourage you to apply.</em></strong>
                  </AwardCategory>
                </AppModal>
              </AwardCategory>
            </div>
          </Row>
        </Container>

        <Container>
          <br /><br /><br /><br />
          <Container>
            <div className="row" style={{
              position: `relative`
            }}>
              <div className="col-lg" style={{
                background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                padding: `3rem`
              }}>
                <div style={{ margin: `0 1.1rem`}}>
                  <h4 style={{
                    display: `inline`,
                    backgroundColor: `#d23464`,
                    padding: `.5rem 0`,
                    lineHeight: `3.4rem`,
                    boxShadow: `1.2rem 0 0 #d23464, -1.2rem 0 0 #d23464`,
                    textTransform: `uppercase`,
                    color: `#fff`,
                    fontFamily: `Avenir`,
                    fontWeight: `900`
                  }}>Special Recognition<br /><small>The Judges Choice</small></h4>
                </div>
                <p style={{
                  color: `#fff`,
                  fontFamily: `Din Pro`,
                  marginTop: `1.5rem`
                }}>Below are the Special Recognition Awards. The Special Recognition Awards are different from the awards mentioned above, with one main distinction: your organisation or team cannot submit an entry to be considered for the Special Recognition awards – they can only be nominated by our expert judges.</p>
                <p style={{
                  color: `#fff`,
                  fontFamily: `Din Pro`,
                  marginTop: `1.5rem`
                }}>
                For your entry to the main award categories to be considered for the Special Recognition awards, before submitting we recommend that you review the criteria which the judges will use to assess entries. This will increase your chances of making the Special Recognition Awards shortlist.</p>
              </div>
              <PlacesBackgroundImage className="col-lg">
                <div style={{
                  background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                  width: `10rem`,
                  height: `10rem`,
                  borderRadius: `50%`,
                  position: `absolute`,
                  bottom: `1.5rem`,
                  right: `-5rem`
                }} />
                <BandsCircle style={{ position: `absolute`, top: `50%`, right: `-16rem`, marginTop: `-9rem`}} size="20rem" />
                <BandsDiamond type="fine" style={{ position: `absolute`, left: `-2.5rem`, top: `-2.5rem`}} />
              </PlacesBackgroundImage>
            </div>
          </Container>
          <br /><br />
          <Container>
            <Row>
              <div className="col-md p-lg-4">
                <AwardCategory title="Special Recognition" gridColor="#30c4bf" icon="star">
                  <AppModal openButton="Read More">
                    <AwardCategory title="Special Recognition" gridColor="#30c4bf" icon="star">
                      <p>The Special Recognition award is just that - a recognition of commitment to inspiring change and transforming services. This award is to celebrate organisations or teams who have shown great ambition in their projects – whether it be through digital innovation, supporting staff and bringing communities together, transforming office spaces or pioneering new strategies to achieve carbon net-zero. It may have come with challenges and lessons learned, but it has set the precedent for what smarter working can achieve.</p>
                      <strong style={{ color: `rgb(210, 52, 100)` }}><em>Please note: Organisations cannot apply to this award and can only be put forward for consideration by Smarter Working Live judges.</em></strong>
                    </AwardCategory>
                  </AppModal>
                </AwardCategory>
              </div>
              <div className="col-md p-lg-4">
                <AwardCategory title="Beyond Smarter Working" gridColor="#d23464" icon="light-bulb-cog">
                  <AppModal openButton="Read More">
                    <AwardCategory title="Beyond Smarter Working" gridColor="#d23464" icon="light-bulb-cog">
                      <p>This prestigious and long-standing award is a combination of all Smarter Working Live awards. It is to celebrate projects that combine people, places, or technology in true smarter working excellence. We are looking for projects that have shown bold ambition, innovation, and collaboration; where careful planning and execution has established cultural change; where new approaches have driven efficiencies or sustainable initiatives; where improved outcomes exceeded expectations and inspired further Smarter Working led projects. This award is about achieving true, long-term change.</p>
                      <strong style={{ color: `rgb(210, 52, 100)` }}><em>Please note: Organisations cannot apply to this award and can only be put forward for consideration by Smarter Working Live judges.</em></strong>
                    </AwardCategory>
                  </AppModal>
                </AwardCategory>
              </div>
            </Row>
            {/* <Row>
              <div className="col-md p-lg-4">
                <AwardCategory title="Smarter Working Maturity" gridColor="#d23464" icon="computer-desk">
                <p>need icon</p>
                </AwardCategory>
              </div>
            </Row> */}
          </Container>
        </Container>
      </Container>
      <br />
      <br />
      <br />
    </Layout>
  </Background>
)

export default CategoriesPage
